import { ImageOverlay, useOnScrollAnimation } from 'modules/app';
import * as styles from 'modules/landing/styles/Content.styles';
import React from 'react';
import AddTag from 'assets/images/roango-skill-add-tag.svg';
import OpeningCard from 'assets/images/opening-card.svg';
import OpeningCardTwo from 'assets/images/opening-card-2.svg';
import RoangoCoverLetter from 'assets/images/roango-cover-letter.svg';
import { FormattedMessage } from 'gatsby-plugin-intl';
import {
  FADE_IN_MOVE_X_LEFT_CONTROLLED,
  FADE_IN_MOVE_X_RIGHT_CONTROLLED,
  FADE_IN_MOVE_Y_CONTROLLED,
  STAGGER_CONTAINER,
} from 'style/animations.config';
import { motion } from 'framer-motion';

export const LandingTalent: React.FC = () => {
  const title = useOnScrollAnimation({ threshold: 0.5 });
  const listItemOne = useOnScrollAnimation({ threshold: 0.5 });
  const listItemTwo = useOnScrollAnimation({ threshold: 0.5 });
  const listItemThree = useOnScrollAnimation({ threshold: 0.5 });
  const images = useOnScrollAnimation({ threshold: 0.5 });

  return (
    <section css={styles.rootSpecial}>
      <div css={styles.talentRoot}>
        <motion.div
          initial="hidden"
          animate={title.animation}
          transition={{ delayChildren: 0.4, staggerChildren: 0.5 }}
          variants={STAGGER_CONTAINER}
          ref={title.ref}
          css={styles.talentTitleWrapper}
        >
          <motion.h2
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
            css={styles.talentTitle}
          >
            <FormattedMessage id="homepage.talent.title" />
          </motion.h2>
          <motion.p
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
            css={styles.talentDescription}
          >
            <FormattedMessage id="homepage.talent.description" />
          </motion.p>
          <motion.p
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
            css={styles.talentDescription}
          >
            <FormattedMessage id="homepage.talent.description_secondary" />
          </motion.p>
        </motion.div>

        <motion.div
          initial="hidden"
          animate={listItemOne.animation}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          ref={listItemOne.ref}
          css={styles.talentTextWrapperPrimary}
        >
          <div css={styles.talentTextCircle}>01</div>
          <h4 css={styles.talentTextTitle}>Skills to Match</h4>
          <p css={styles.talentTextDescription}>
            Add your top skills to your Roango profile and instantly connect
            with the most relevant employers. With our anonymity feature, you
            control when to reveal your identity and take your career to the
            next level.
          </p>
        </motion.div>
        <div css={styles.talentImageWrapperPrimary}>
          <AddTag css={styles.image} />
          <ImageOverlay
            threshold={0.3}
            type="top"
            background="hsl(var(--color-grayscale-9))"
          />
        </div>
        <div css={styles.talentImageWrapperSecondary}>
          <RoangoCoverLetter css={styles.image} />
          <ImageOverlay
            threshold={0.3}
            type="top"
            background="hsl(var(--color-grayscale-9))"
          />
        </div>
        <motion.div
          initial="hidden"
          animate={listItemTwo.animation}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          ref={listItemTwo.ref}
          css={styles.talentTextWrapperSecondary}
        >
          <div css={styles.talentTextCircle}>02</div>
          <h4 css={styles.talentTextTitle}>Boost Your Profile</h4>
          <p css={styles.talentTextDescription}>
            Highlight your experience, skills, projects, and qualifications to
            make a great first impression. With Roango's Talent Profile, you can
            export and share it to unlock more opportunities.
          </p>
        </motion.div>
        <motion.div
          initial="hidden"
          animate={listItemThree.animation}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          ref={listItemThree.ref}
          css={styles.talentTextWrapperTertiary}
        >
          <div css={styles.talentTextCircle}>03</div>
          <h4 css={styles.talentTextTitle}>Game on!</h4>
          <p css={styles.talentTextDescription}>
            Take a step back and wait for the perfect match or actively search
            for your desired job without revealing your identity through our
            stealth mode.
          </p>
        </motion.div>
        <motion.div
          initial="hidden"
          animate={images.animation}
          transition={{ delayChildren: 0.4, staggerChildren: 0.5 }}
          variants={STAGGER_CONTAINER}
          ref={images.ref}
          css={styles.talentImageWrapperTertiary}
        >
          <motion.div
            variants={FADE_IN_MOVE_X_LEFT_CONTROLLED}
            transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
            css={styles.talentImageStackedPrimary}
          >
            <OpeningCard css={styles.talentImageStacked} />
          </motion.div>
          <motion.div
            variants={FADE_IN_MOVE_X_RIGHT_CONTROLLED}
            transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
            css={styles.talentImageStackedSecondary}
          >
            <OpeningCardTwo css={styles.talentImageStacked} />
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};
