import { HttpClient } from 'modules/app/http/client/http';

export class TalentApplicationsServices extends HttpClient<
  DashboardApplication[]
> {
  constructor() {
    super('/api/v1/talent/openings/applications');
  }
}

export class TalentInvitationsServices extends HttpClient<Invitation[]> {
  constructor() {
    super('/api/v1/talent/openings/invitations');
  }
}

export class TalentSettingsService extends HttpClient<undefined> {
  constructor() {
    super('/api/v1/settings');
  }
}
