export function extractBasics(data: TalentDataPreview): PreviewBasics {
  const basics: PreviewBasics = {
    about: null,
    cityCountry: null,
    disability: null,
    dob: '',
    firstName: '',
    gender: null,
    lastName: '',
    location: null,
    phone: null,
    title: null,
    workplace: [],
    profilePicture: null,
    coverPicture: null,
    expertises: [],
    portfolios: null,
  };

  Object.keys(data)
    .filter(
      (key) =>
        ![
          'culture',
          'certificates',
          'educations',
          'experiences',
          'languages',
          'projects',
        ].includes(key),
    )
    .forEach((key) => (basics[key] = data[key]));

  return basics;
}
