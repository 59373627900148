export const whyRoangoSectionData = {
  title: 'Stand out with an Eye-Catching Talent Profile.',
  titleSpecial: 'Ready to Rebel Against the Resume?',
  listDescriptions: [
    'Get noticed based on your skills and abilities. This ensures the best possible match with potential employers.',
    'With direct access to employers on Roango you don’t have to search high and wide anymore. Sit back and enjoy life.',
    'Roango is designed to help you find the perfect job, leading to a fulfilling career and a happy employer-employee relationship.',
  ],
  description:
    'Create an account and find out now how Roango can find the right match for you.',
};
