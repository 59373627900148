import { motion } from 'framer-motion';
import { Section, Social } from 'modules/profilePreview/components';
import React from 'react';
import { BOUNCE, FADE_IN } from 'style/animations.config';
import { previewData } from '../const/previewData';
import * as styles from 'modules/landing/styles/DemoPreview.styles';
import * as profileStyles from 'modules/profilePreview/styles/Basics.styles';
import { MainContent, Avatar } from 'modules/app';
import { Info } from 'modules/profilePreview/components/basic/Info';
import AvatarImage from 'assets/images/preview-avatar.png';
import CoverImage from 'assets/images/preview-cover.jpg';
import Experience from 'modules/profilePreview/components/experience/Root';
import Portfolio from 'modules/profilePreview/components/portfolio/Root';
import Projects from 'modules/profilePreview/components/projects/Root';
import Certificates from 'modules/profilePreview/components/certificates/Root';
import Education from 'modules/profilePreview/components/education/Root';
import Language from 'modules/profilePreview/components/languages/Root';
import Close from 'assets/icons/close.svg';
import ReactDOM from 'react-dom';
import { Link } from 'gatsby-plugin-intl';

interface Props {
  setDemoClosed: VoidFunction;
}

export const DemoPreview: React.FC<Props> = ({ setDemoClosed }) => {
  return ReactDOM.createPortal(
    <motion.div
      initial="hidden"
      animate="animate"
      variants={FADE_IN}
      transition={{ duration: 0.3, ease: 'easeOut' }}
      exit={{
        opacity: 0,
        transition: { duration: 0.3, ease: 'easeOut' },
      }}
      css={styles.baseWrapper}
    >
      <div css={styles.wrapper}>
        <div css={styles.contentWrapper}>
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.5,
              type: 'keyframes',
              ease: 'easeInOut',
              delay: 0.3,
            }}
            css={styles.closeButton}
            onClick={setDemoClosed}
          >
            <Close />
          </motion.button>
          <motion.div
            initial="hidden"
            animate="animate"
            variants={BOUNCE}
            transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
            css={styles.base}
          >
            <div css={styles.root(CoverImage)}>
              <MainContent customStyles={profileStyles.contentRoot}>
                <div css={profileStyles.avatarWrapper}>
                  <Avatar
                    isBase64={true}
                    customStyles={profileStyles.avatar}
                    imagePath={AvatarImage}
                    name={previewData.firstName}
                    lastName={previewData.lastName}
                  />
                </div>

                <Info
                  location={previewData.cityCountry}
                  firstName={previewData.firstName}
                  lastName={previewData.lastName}
                  about={previewData.about}
                  title={previewData.title}
                  phone={previewData.phone}
                  tags={previewData.expertises}
                />

                {previewData.portfolios && (
                  <div css={profileStyles.socialWrapper}>
                    <Social portfolios={previewData.portfolios} />
                  </div>
                )}
              </MainContent>
            </div>

            <Section theme="black" customStyles={styles.section}>
              <Experience listing={previewData.experiences} />
            </Section>

            <Section theme="white" customStyles={styles.section}>
              <Portfolio listing={previewData.portfolios} />
            </Section>

            <Section theme="gray" customStyles={styles.section}>
              <Projects listing={previewData.projects} />
            </Section>

            <Section theme="white" customStyles={styles.section}>
              <Certificates listing={previewData.certificates} />
            </Section>

            <Section theme="gray" customStyles={styles.section}>
              <Education listing={previewData.educations} />
            </Section>

            <Section theme="white" customStyles={styles.section}>
              <Language listing={previewData.languages} />
            </Section>

            <div css={styles.textWrapper}>
              <motion.h2 css={styles.title}>
                Ready to elevate your career?
              </motion.h2>
              <p css={styles.description}>
                Join our talent pool and create your professional talent profile
                in minutes!
              </p>
              <Link to="/register" css={styles.link}>
                <span>Join Roango</span>
              </Link>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.div>,
    document.body,
  );
};
