import { motion } from 'framer-motion';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { useOnScrollAnimation } from 'modules/app';
import * as styles from 'modules/landing/styles/Content.styles';
import React from 'react';
import {
  FADE_IN_MOVE_Y_CONTROLLED,
  STAGGER_CONTAINER,
} from 'style/animations.config';
import { CompanyCards } from './CompanyCards';

export const LandingCompany: React.FC = () => {
  const textContainer = useOnScrollAnimation({ threshold: 0.3 });

  return (
    <>
      <motion.section
        initial="hidden"
        animate={textContainer.animation}
        transition={{ delayChildren: 0.4, staggerChildren: 0.5 }}
        variants={STAGGER_CONTAINER}
        ref={textContainer.ref}
        css={styles.companyRoot}
      >
        <motion.h2
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          css={styles.title}
        >
          <FormattedMessage id="homepage.company.title" />
        </motion.h2>
        <motion.p
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          css={styles.description}
        >
          <FormattedMessage id="homepage.company.description" />
        </motion.p>
      </motion.section>
      <CompanyCards />
    </>
  );
};
