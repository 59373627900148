import { SSRError } from 'modules/app';
import React from 'react';
import { Section } from './Section';
import BasicsRoot from 'modules/profilePreview/components/basic/Root';
import Experience from 'modules/profilePreview/components/experience/Root';
import Portfolio from 'modules/profilePreview/components/portfolio/Root';
import Projects from 'modules/profilePreview/components/projects/Root';
import Certificates from 'modules/profilePreview/components/certificates/Root';
import Education from 'modules/profilePreview/components/education/Root';
import Language from 'modules/profilePreview/components/languages/Root';
import { extractBasics } from '../utils';
import * as styles from 'modules/app/styles/AppLayout.styles';
import { Link } from 'gatsby-plugin-intl';

interface Props {
  user?: TalentDataPreview;
  error?: ApiErrorData;
}

export const TalentPublicPreview: React.FC<Props> = ({ user, error }) => {
  return (
    <main>
      {error && <SSRError error={error} />}
      {user && (
        <section>
          <BasicsRoot data={extractBasics(user)} isPublic />

          {Boolean(user.experiences.length) && (
            <Section theme="black">
              <Experience listing={user.experiences} isPublic />
            </Section>
          )}

          {Boolean(user.portfolios.length) && (
            <Section theme="white">
              <Portfolio listing={user.portfolios} isPublic />
            </Section>
          )}

          {Boolean(user.projects.length) && (
            <Section theme="gray">
              <Projects listing={user.projects} isPublic />
            </Section>
          )}

          {Boolean(user.certificates.length) && (
            <Section theme="white">
              <Certificates listing={user.certificates} isPublic />
            </Section>
          )}

          {Boolean(user.educations.length) && (
            <Section theme="gray">
              <Education listing={user.educations} isPublic />
            </Section>
          )}

          {Boolean(user.languages.length) && (
            <Section theme="white">
              <Language listing={user.languages} isPublic />
            </Section>
          )}
        </section>
      )}

      <footer css={styles.talentFooter}>
        <p>
          Powered by <Link to="/">Roango</Link>
        </p>
      </footer>
    </main>
  );
};
