import React, { useEffect, useState } from 'react';
import {
  DemoPreview,
  LandingCompany,
  LandingHeader,
  LandingTalent,
} from './components';
import { Footer } from 'modules/app';

import * as styles from './styles/Root.styles';
import { RouteComponentProps } from '@reach/router';
import { Navbar } from 'modules/app/components/shared/Navbar';
import { WhyRoangoSection } from 'modules/shared';
import { whyRoangoSectionData } from './const/why-roango-section-data';
import { AnimatePresence } from 'framer-motion';
import { Link } from 'gatsby-plugin-intl';

export const LandingPage: React.FC<RouteComponentProps> = () => {
  const [isDemoOpen, setIsDemoOpen] = useState(false);

  function setDemoOpen() {
    setIsDemoOpen(true);
  }

  function setDemoClosed() {
    setIsDemoOpen(false);
  }

  useEffect(() => {
    if (isDemoOpen) {
      document.body.style.overflow = 'hidden';
      return;
    }

    document.body.style.overflow = 'auto';
  }, [isDemoOpen]);

  return (
    <>
      <Navbar isLight />
      <AnimatePresence exitBeforeEnter>
        {isDemoOpen && <DemoPreview setDemoClosed={setDemoClosed} />}
      </AnimatePresence>
      <div css={styles.wrapper}>
        <LandingHeader setDemoOpen={setDemoOpen} />
        <LandingCompany />
      </div>
      <LandingTalent />
      <WhyRoangoSection {...whyRoangoSectionData} />
      <Link css={styles.joinButton} to="/register">
        <span>Join Roango</span>
      </Link>
      <Footer />
    </>
  );
};
