import { useCsrfCookie, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TalentApplicationsServices } from '../http';
import { talentDashboardActions } from '../redux';

export const useApplications = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<HttpError>();

  const { toastSuccess } = useToastify();
  const { getCsrf } = useCsrfCookie();
  const dispatch = useDispatch();
  const csrf = getCsrf();

  const applicationsHttp = useMemo(
    () => new TalentApplicationsServices(),
    [csrf],
  );

  async function getActiveApplications() {
    try {
      const { data } = await applicationsHttp.get<DashboardApplication[]>(
        '/active',
      );
      const active = data.filter((d) => d.companyPipelineStage !== 'Hired');
      dispatch(talentDashboardActions.setActiveApplications(active));
      return;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      return e;
    }
  }

  async function getRequestedApplications() {
    try {
      const { data } = await applicationsHttp.get<RequestedApplication[]>(
        '/requests',
      );

      dispatch(talentDashboardActions.setRequestedApplications(data));
      return;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      return e;
    }
  }

  async function getDisqualifiedApplications() {
    try {
      const { data } = await applicationsHttp.get('/disqualified');
      dispatch(talentDashboardActions.setDisqualifiedApplications(data));
      return;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      return e;
    }
  }

  async function rejectCv(applicationId: string, callback: VoidFunction) {
    setIsLoading(true);

    try {
      await applicationsHttp.post(`${applicationId}/reject-cv-request`);
      toastSuccess('shared.pills.successDefaultText');
      getRequestedApplications();
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }

    setIsLoading(false);
  }

  async function showCv(applicationId: string, callback: VoidFunction) {
    setIsLoading(true);

    try {
      await applicationsHttp.post(`/${applicationId}/reveal`);
      toastSuccess('shared.pills.successDefaultText');
      getRequestedApplications();
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }

    setIsLoading(false);
  }

  async function revokeApplication(
    applicationId: string,
    callback: VoidFunction,
  ) {
    setIsLoading(true);

    try {
      await applicationsHttp.post(`/${applicationId}/revoke`);
      toastSuccess('shared.pills.successDefaultText');
      getActiveApplications();
      getDisqualifiedApplications();
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }

    setIsLoading(false);
  }

  return {
    isLoading,
    error,
    getActiveApplications,
    getRequestedApplications,
    getDisqualifiedApplications,
    rejectCv,
    showCv,
    revokeApplication,
  };
};
