import { useCsrfCookie, useToastify } from 'modules/app';
import { useMandatoryRefresh } from 'modules/auth';
import { HttpError } from 'modules/app/http/client/httpError';
import { AppState } from 'modules/redux-store';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TalentSettingsService } from '../http';
import { talentDashboardActions } from '../redux';
import { useApplications } from './useApplications';
import { useInvitations } from './useInvitations';

export const useDashboardActions = () => {
  const {
    getActiveApplications,
    getRequestedApplications,
    getDisqualifiedApplications,
  } = useApplications();
  const dispatch = useDispatch();
  const { getCsrf } = useCsrfCookie();
  const { mandatoryRefresh } = useMandatoryRefresh();
  const { toastSuccess, toastError } = useToastify();
  const { getActiveInvitations, getRejectedInvitations } = useInvitations();

  const csrf = getCsrf();
  const { user } = useSelector((state: AppState) => state.session);
  const settingsHttp = useMemo(() => new TalentSettingsService(), [csrf]);

  async function getDashboardData() {
    dispatch(talentDashboardActions.loading(true));

    const res = await Promise.all([
      getActiveApplications(),
      getRequestedApplications(),
      getDisqualifiedApplications(),
      getActiveInvitations(),
      getRejectedInvitations(),
    ]);

    const error = res.find((r) => r !== undefined);

    if (error) {
      dispatch(talentDashboardActions.loading(false));
      return;
    }

    dispatch(talentDashboardActions.loading(false));
  }

  async function changePrivacyMode() {
    try {
      await settingsHttp.put('/user-privacy-mode', {
        privacyMode: user?.privacyMode === 'private' ? 'public' : 'private',
      });
      await mandatoryRefresh();
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      toastError();
    }
  }

  return {
    getDashboardData,
    changePrivacyMode,
  };
};
