import { motion } from 'framer-motion';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import { ImageOverlay, Paragraph } from 'modules/app';
import * as styles from 'modules/landing/styles/Header.styles';
import React, { useRef } from 'react';
import { FADE_IN_MOVE_Y_CONTROLLED } from 'style/animations.config';
import { StaticImage } from 'gatsby-plugin-image';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';

interface Props {
  setDemoOpen: VoidFunction;
}

export const LandingHeader: React.FC<Props> = ({ setDemoOpen }) => {
  const ref = useRef(null);

  return (
    <motion.section
      initial="hidden"
      animate="visible"
      css={styles.contentWrapper}
      ref={ref}
      viewport={{ root: ref }}
      transition={{ delayChildren: 0.4, staggerChildren: 0.5 }}
    >
      <div css={styles.textWrapper}>
        <motion.h1
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          css={styles.title}
        >
          <FormattedMessage id="homepage.header.title" />
        </motion.h1>
        <motion.p
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          css={styles.description}
        >
          <FormattedMessage
            id="homepage.header.description"
            values={{
              talentProfile: (
                <span css={font.weight.bold}>
                  <FormattedMessage id="homepage.header.talent_profile" />
                </span>
              ),
            }}
          />
        </motion.p>
        <motion.div
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{
            duration: 0.5,
            type: 'keyframes',
            ease: 'easeInOut',
          }}
          css={styles.wrapper}
        >
          <Link to="/register" css={styles.link}>
            <span>
              <FormattedMessage id="buttons.joinRoango" />
            </span>
          </Link>
          <Paragraph
            size="fluidMedium"
            fontFamily="primary"
            css={styles.details}
          >
            <span>
              <FormattedMessage id="homepage.header.free" />{' '}
            </span>
            <FormattedMessage id="homepage.header.for_talents" />
          </Paragraph>
        </motion.div>
      </div>
      <div css={styles.imageAndTextWrapper}>
        <div css={styles.imageWrapper}>
          <StaticImage
            formats={['auto', 'webp', 'avif']}
            loading="lazy"
            placeholder="blurred"
            css={styles.heaederImage}
            src="../../../assets/images/roango-talent-profile-card.jpg"
            alt=""
            quality={100}
          />
          <ImageOverlay
            threshold={0.3}
            background="hsl(var(--color-borderUltraLight-10))"
          />
          <button css={styles.buttonSpecial} onClick={setDemoOpen} />
        </div>
        <motion.button
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{
            duration: 0.5,
            type: 'keyframes',
            ease: 'easeInOut',
          }}
          css={styles.previewButton}
          onClick={setDemoOpen}
        >
          <FormattedMessage id="homepage.header.preview" />
        </motion.button>
      </div>
    </motion.section>
  );
};
