import { useCsrfCookie } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TalentInvitationsServices } from '../http';
import { talentDashboardActions } from '../redux';

export const useInvitations = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<HttpError>();

  const { getCsrf } = useCsrfCookie();
  const dispatch = useDispatch();
  const csrf = getCsrf();

  const invitationsHttp = useMemo(
    () => new TalentInvitationsServices(),
    [csrf],
  );

  async function getActiveInvitations() {
    try {
      const { data } = await invitationsHttp.get('/active');
      dispatch(talentDashboardActions.setActiveInvitations(data));
      return;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      return e;
    }
  }

  async function getRejectedInvitations() {
    try {
      const { data } = await invitationsHttp.get<DashboardApplication[]>(
        '/rejected',
      );
      dispatch(talentDashboardActions.setRejectedInvitations(data));
      return;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      return e;
    }
  }

  async function rejectInvitation(
    invitationId: string,
    callback: VoidFunction,
  ) {
    setIsLoading(true);

    try {
      await invitationsHttp.post(`/${invitationId}/reject`);
      getActiveInvitations();
      getRejectedInvitations();
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }

    setIsLoading(false);
  }

  return {
    isLoading,
    error,
    getActiveInvitations,
    getRejectedInvitations,
    rejectInvitation,
  };
};
