import { css } from '@emotion/react';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';
import { breakpoints } from 'style/variables';

export const wrapper = css`
  background: hsl(var(--color-borderUltraLight-10));
  box-sizing: content-box;
`;

export const joinButton = css`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  padding: 16px 52px;
  margin: 0 auto;
  width: max-content;
  border-radius: 100px;
  background: hsl(var(--color-robin-2));
  color: hsl(var(--color-text-1));
  border: none;
  ${font.size.base};
  ${font.weight.bold};
  z-index: 2;

  &:hover,
  &:active {
    background: hsl(var(--color-text-1));
    color: hsl(var(--color-white-11));
  }

  @media ${breakpoints.medium} {
    display: none;
  }
`;
