import React from 'react';
import * as styles from 'modules/landing/styles/Cards.styles';
import Barrage from 'assets/icons/companies/barrage-logo.svg';
import Greyp from 'assets/icons/companies/greyp-logo.svg';
import Mono from 'assets/icons/companies/mono-logo.svg';
import Sartura from 'assets/icons/companies/sartura-logo.svg';
import Typeqast from 'assets/icons/companies/typeqast-logo.svg';
import { motion } from 'framer-motion';
import {
  FADE_IN_MOVE_Y_CONTROLLED,
  STAGGER_CONTAINER,
} from 'style/animations.config';
import { useOnScrollAnimation } from 'modules/app';

export const CompanyCards: React.FC = () => {
  const contaienr = useOnScrollAnimation({ threshold: 0.5 });

  return (
    <motion.section
      initial="hidden"
      animate={contaienr.animation}
      transition={{ delayChildren: 0.4, staggerChildren: 0.5 }}
      variants={STAGGER_CONTAINER}
      ref={contaienr.ref}
      css={styles.companies}
    >
      <div css={styles.wrapper}>
        <div css={styles.listWrapper}>
          <div css={styles.companiesList}>
            <motion.div
              variants={FADE_IN_MOVE_Y_CONTROLLED}
              transition={{
                duration: 0.5,
                type: 'keyframes',
                ease: 'easeInOut',
              }}
              css={styles.base}
            >
              <Greyp />
            </motion.div>
            <motion.div
              variants={FADE_IN_MOVE_Y_CONTROLLED}
              transition={{
                duration: 0.5,
                type: 'keyframes',
                ease: 'easeInOut',
              }}
              css={styles.base}
            >
              {' '}
              <Sartura />
            </motion.div>
            <motion.div
              variants={FADE_IN_MOVE_Y_CONTROLLED}
              transition={{
                duration: 0.5,
                type: 'keyframes',
                ease: 'easeInOut',
              }}
              css={styles.base}
            >
              {' '}
              <Mono />
            </motion.div>
            <motion.div
              variants={FADE_IN_MOVE_Y_CONTROLLED}
              transition={{
                duration: 0.5,
                type: 'keyframes',
                ease: 'easeInOut',
              }}
              css={styles.base}
            >
              {' '}
              <Typeqast />
            </motion.div>
            <motion.div
              variants={FADE_IN_MOVE_Y_CONTROLLED}
              transition={{
                duration: 0.5,
                type: 'keyframes',
                ease: 'easeInOut',
              }}
              css={styles.base}
            >
              {' '}
              <Barrage />
            </motion.div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};
