import Seo from 'modules/app/components/Seo';
import { LandingPage } from 'modules/landing';
import React from 'react';
import { CookieConsent, PublicLayout } from '../modules/app';
import useSiteMetadata from 'modules/app/graphql/useSiteMetadata';

const Index: React.FC<WebPageProps> = ({ pageContext }) => {
  const { siteUrl } = useSiteMetadata();
  const { intl } = pageContext;
  const path = `${siteUrl}/${intl.language}${intl.originalPath}`;

  return (
    <>
      <Seo
        title="Roango - Connecting Tech Talents with Great Companies"
        description="Recommended jobs at awesome companies based on skill, culture-fit, location and more."
        url={path}
        image={`${siteUrl}/roango-share-image.png`}
      />

      <PublicLayout>
        <LandingPage />
      </PublicLayout>

      <CookieConsent />
    </>
  );
};

export default Index;
