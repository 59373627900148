import { Button, useOutOfBounds, useOutsideClick } from 'modules/app';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Share from 'assets/icons/share.svg';
import * as styles from '../styles/ShareProfileButton.styles';
import { AnimatePresence, motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_CONTROLLED } from 'style/animations.config';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { useLocation } from '@reach/router';
import copy from 'copy-to-clipboard';
import { useDashboardActions } from 'modules/talentDashboard/hooks';
import { FormattedMessage } from 'gatsby-plugin-intl';

export const ShareProfileButton: React.FC = () => {
  const { origin } = useLocation();
  const { changePrivacyMode } = useDashboardActions();
  const { user } = useSelector((state: AppState) => state.session);
  const isChecked = useMemo(
    () => Boolean(user?.privacyMode === 'public'),
    [user?.privacyMode],
  );
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOutsideClick<HTMLDivElement>(() => setIsOpen(false));
  const contentRef = useRef<HTMLDivElement>(null);
  useOutOfBounds(contentRef, isOpen);

  const link = `${origin}/talent/${user?.id}`;

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    changePrivacyMode();
  }, []);

  return (
    <div css={styles.root} ref={ref}>
      <Button
        size="small"
        type="button"
        buttonType="outline"
        css={isOpen ? styles.btnActive : styles.btn}
        icon={<Share />}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span css={styles.btnText}>
          <FormattedMessage id="profileManagement.heading.share" />
        </span>
      </Button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            initial="hidden"
            animate="visible"
            exit="hidden"
            css={styles.content}
            ref={contentRef}
          >
            <div css={styles.checkbox}>
              <div>
                <p css={styles.shareText}>Share Your Profile</p>
                <p css={styles.description}>
                  Anyone with the link can view your profile.
                </p>
              </div>

              <div>
                <input
                  css={styles.input}
                  checked={false}
                  id="isPublic"
                  type="checkbox"
                  name="isPublic"
                  onChange={onChange}
                />
                <label css={styles.label} htmlFor="isPublic">
                  <span
                    css={isChecked ? styles.controlChecked : styles.control}
                  >
                    <div
                      css={isChecked ? styles.toggleChecked : styles.toggle}
                    />
                  </span>
                </label>
              </div>
            </div>

            {isChecked && (
              <div css={styles.linkWrapper}>
                <p css={styles.link}>{link}</p>
                <Button
                  buttonType="secondary"
                  size="tiny"
                  onClick={() => {
                    copy(link);
                  }}
                >
                  <FormattedMessage id="profileManagement.heading.copy" />
                </Button>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
